import './ControlButtons.css'; // Подключаем стили
import './Palette/ColorPalette.css'; // Подключаем стили

import React, { useState, useEffect } from 'react';

import { 
  FaBrush, FaSprayCan, FaPencilAlt, FaFeatherAlt, FaPaintBrush, FaPenFancy, 
  FaFillDrip, FaTint, 
  FaSun, FaMoon, 
  FaHotjar, FaToiletPaper, 
  FaEraser, FaPalette, FaUndoAlt, FaRedoAlt, FaTimes, FaPencilRuler,
  FaDotCircle,
  FaPaw, 
  FaDrawPolygon,
} from 'react-icons/fa';
import { 
  FaMarker,
  FaEyeDropper,
  FaBroomBall,
  FaMagnifyingGlass,
  FaRegLightbulb,
} from 'react-icons/fa6';
import { 
  TbBrush,
  TbLineDashed,
  TbGrain,
  TbContrast2Filled, TbContrast2Off,
  TbZoomIn, TbZoomOut,
  TbX,
  TbSparkles,
  TbTexture,
 } from 'react-icons/tb';
 import { MdGridOn } from "react-icons/md";



import ColorPalettePopup from './Palette/ColorPalettePopup.js';
import PremiumGrayscalePalette from './Palette/PremiumGrayscalePalette.js';
import GrayscalePicker from './Palette/GrayscalePicker.js';
import BrushSizeControl from './BrushSizeControl';

import SoftnessControl from './SoftnessControl.js'; 
import BrightnessControl from './BrightnessControl'; 
import OpacityControl from './OpacityControl'; 
import MagnifierIndicator from './Magnifier/MagnifierIndicator.js';
import EraserMagnifier from './Magnifier/EraserMagnifier.js';

// import SaturationControl from './SaturationControl'; 
// import HueControl from './HueControl';

import CircleSwitch from "./CircleSwitch/CircleSwitch.js";


function ControlButtons({
  gameContext,
  canvasContext,
  brushContext,
  drawMethods,
}) {

    const { 
      gameInfo,
      gameInfoRef,
      userSetsRef,
      getUserGameSets,

      premiumFeaturesRef,
      openMenu,

      appleHaptic,
     } = gameContext;

    const { 
      zoomFactor,
      isDrawingRef,
      
      isTouchDevice,
      mouseDetected,
     } = canvasContext;

    const { 
      color, 
      gradientColorFun,
      brushSetsFun,
      
      showPalette, setShowPalette,
      setGradientPalette,

      premiumGrayscale,
      activeTool,

      isPipettePicking,

      brushTypeRef,
      effectTypeRef,
      clipboardColor,

      visibleLineWidth,

      magnifierOn, setMagnifierOn,
     } = brushContext;

    const { 
      changeTool,
      undoLastAction, 
      redoLastAction,
      confirmClearCanvas, 
     } = drawMethods;

     const sets = userSetsRef.current[brushTypeRef.current] || {};
     const basicBrushLink = sets?.clone || brushTypeRef.current;

    const commonSets = brushSetsFun('common');

    const showMagnifierButton = commonSets.magnifierButton && isTouchDevice

    const showMagnifierByTurnedOn = 
    isDrawingRef.current 
    && magnifierOn
    && visibleLineWidth < 30 
    && (isTouchDevice && !mouseDetected);

    const showMagnifierByTool = 
    ((activeTool === 'eraser' && commonSets.eraserMagnifier) || (activeTool === 'brush' && commonSets.brushMagnifier)) 
    && isDrawingRef.current 
    && visibleLineWidth < 30 
    && (isTouchDevice && !mouseDetected)

    const showMagnifier = showMagnifierByTurnedOn || showMagnifierByTool;

    const BrushIcon = {
      plain: FaMarker,
      shape: FaDrawPolygon,
      outlined: FaDotCircle,
      bristle: FaPaintBrush,
      rembrandt: FaBroomBall,
      feather: FaFeatherAlt,
      ink: FaPenFancy,
      pencil: FaPencilAlt,
      oil: FaBrush,
      watercolor: TbBrush,
      dashed: TbLineDashed,
      spray: FaSprayCan,
      neon: FaRegLightbulb,
      sparkle: TbSparkles,
      test: FaPaw,
    }[basicBrushLink] || FaPaintBrush;

    const EffectIcon = {
      filler: FaFillDrip,
      blur: FaTint,
      noise: TbGrain,
      pixelate: MdGridOn,
      lighten: FaSun,
      darken: FaMoon,
      saturate: FaHotjar,
      desaturate: FaToiletPaper,
      contrast: TbContrast2Filled,
      decontrast: TbContrast2Off,
      zoomin: TbZoomIn,
      zoomout: TbZoomOut,
      texture: TbTexture,
    }[effectTypeRef.current] || FaFillDrip;

    return (
      <div className='controls-frame'>

        {/* Верхний ряд с инструментами */}
        <div className='controls-row'>

            {
              gameInfo?.mode === 'sprint' ? (
                <CircleSwitch
                  icon={<BrushIcon />}
                  isActive={activeTool === "brush"}
                  onClick={() => changeTool("brush")}
                  onContextMenu={()=>{
                    const brush = brushTypeRef.current === 'plain' ? 'common' : brushTypeRef.current;
                    if (['common', 'shape', 'dashed', 'outlined'].includes(brush)) {
                      openMenu('customizeBrush', { brush, layer: true})
                    } else {
                      changeTool("brush")
                    }
                  }}
                />
              ) : 
              (
                <SoftnessControl 
                  canvasContext={canvasContext}
                  gameContext={gameContext}
                  brushContext={brushContext} 
                  drawMethods={drawMethods} 
                  icon={<BrushIcon />}

                  onContextMenu={()=>{
                    // changeTool("brush");
                    const brush = brushTypeRef.current === 'plain' ? 'common' : brushTypeRef.current;
                    openMenu('customizeBrush', { brush, layer: true})
                  }}
                />
              )
            }

            <CircleSwitch
              icon={<FaEraser />}
              isActive={activeTool === "eraser"}
              onClick={() => changeTool("eraser")}
            />

            <OpacityControl 
                canvasContext={canvasContext}
                brushContext={brushContext} 
                drawMethods={drawMethods} 

                icon= {<EffectIcon />}
                available={premiumFeaturesRef.current.includes('effect') && !userSetsRef.current.premiumOff}

                onContextMenu={()=>{
                  // changeTool("effect");
                  const effect = ['filler', 'pixelate', 'texture'].includes(effectTypeRef.current) ? effectTypeRef.current : 'filler';
                  openMenu('customizeBrush', {brush: effect, effect: true, layer: true})
                }}
              />

            <BrightnessControl 
              canvasContext={canvasContext}
              brushContext={brushContext} 
              drawMethods={drawMethods} 

              icon= {<FaEyeDropper />}
              available={premiumFeaturesRef.current.includes('pipette') && !userSetsRef.current.premiumOff}
            />

            <div 
                className='allow-context-menu'
                onContextMenu={()=>{
                  if (getUserGameSets().gradientOn) {
                    setGradientPalette(true);
                  }
                  setShowPalette(true);
                }}
                // onContextMenu={clipboardColor}
                  // #000000
              >
              <CircleSwitch
                id="paletteToggleButton" 
                icon={<FaPalette />}
                isActive={showPalette}
                color={color}
                gradientColor={gradientColorFun()}
                onClick={() => setShowPalette(true)}
                onTouchEnd={()=>{setTimeout(() => {  setShowPalette(true)  }, 100);}}
              />    
            </div>

        </div>
        
        {/* Нижний ряд с управляющими кнопками */}
        <div className='controls-row'>

        <CircleSwitch
          icon={<FaUndoAlt />}
          onClick={(e) => { undoLastAction(); }}
        />

        <CircleSwitch
          icon={<FaRedoAlt />}
          onClick={(e) => { redoLastAction(); }}
        />

        <CircleSwitch
          icon={<FaTimes />}
          onClick={(e) => {
            confirmClearCanvas();
          }}
        />

          {showMagnifierButton && (
            <CircleSwitch
              icon={<FaMagnifyingGlass />}
              isActive={magnifierOn}
              onClick={(e) => {
                setMagnifierOn(!magnifierOn);
                appleHaptic('soft');
              }}
            />
          )}
        

          <BrushSizeControl 
            gameContext={gameContext}
            canvasContext={canvasContext}
            icon={<FaPencilRuler />}
            brushContext={brushContext} 
          />
        </div>

    {/* userSetsRef.current.premiumOff */}

    {showPalette && (
      (!premiumFeaturesRef.current.includes('colors')
      || userSetsRef.current.premiumOff) ? (
      <GrayscalePicker
        gameContext={gameContext}
        brushContext={brushContext}
        canvasContext={canvasContext}
      />
      ) : premiumGrayscale ? (
        <PremiumGrayscalePalette
          gameContext={gameContext}
          brushContext={brushContext}
          canvasContext={canvasContext}
        />
      ) : (
        <ColorPalettePopup
          gameContext={gameContext}
          brushContext={brushContext}
          canvasContext={canvasContext}
        />
      )
      )}
      {isPipettePicking && (
          <MagnifierIndicator
            gameContext={gameContext}
            brushContext={brushContext}
            canvasContext={canvasContext}
            drawMethods={drawMethods}
          />
      )}
      { showMagnifier && (
          <EraserMagnifier
            gameContext={gameContext}
            brushContext={brushContext}
            canvasContext={canvasContext}
            drawMethods={drawMethods}
          />
      )}
</div>
    );
}

export default ControlButtons;
