// ChatOverlay.js
import React, { useContext, useEffect, useState, useRef} from 'react';
import { useTranslation } from 'react-i18next';

import { useGameContext } from '../../../contexts/GameContext';
import { useHistoryContext } from '../../../contexts/HistoryContext';

function ConnectionIndicator({
  drawMethods={drawMethods}
}) {
    const { t } = useTranslation();

    const {
      groupRedraw,
    } = drawMethods;

    const gameContext = useGameContext();
    const { 
      connectionStatusRef, 
      PORT,
      gameInfoRef,
     } = gameContext;

    const historyContext = useHistoryContext();
    const { 
      userStrokesRef,
     } = historyContext;

    const [indicatorStr, setIndicatorStr] = useState('');  // Состояние для контроля видимости чата
    const onIndicatorClickRef = useRef(null);  // Состояние для контроля видимости чата


    useEffect(() => {
      const intervalId = setInterval(() => {

        if (!gameInfoRef.current) {return;}

        onIndicatorClickRef.current = groupRedraw;

        const allStrokes = Object.values(userStrokesRef.current).flat();
        const combinedStrokes = allStrokes.filter(stroke => !stroke.cancelled).sort((a, b) => a.time - b.time);

        const lastStroke = combinedStrokes[combinedStrokes.length - 1];
        let lastStrokeTime = lastStroke?.time || 0;
        if (lastStroke?.type === 'clear') {lastStrokeTime = 0;}

        const {
          lastSaveTime,
          lastConnectionErrorTime,
          lastPreviewTime,
        } = connectionStatusRef.current;
        
        if (lastStrokeTime === gameInfoRef.current.lastRenderedStrokeTime) {
          setIndicatorStr(t('connection.published'));
          onIndicatorClickRef.current = null;
        } else if (
          lastConnectionErrorTime > lastSaveTime
          ) {
          setIndicatorStr(t('connection.lost'))
        } else {
          setIndicatorStr(t('connection.force'))
        }
        
      }, 300);

      return () => clearInterval(intervalId);
    }, []);

    return (
      <>
        { indicatorStr && <div 
          onClick={onIndicatorClickRef.current}
            style={{
              position: 'absolute',
              top: '4px',
              left: '15px',
              zIndex: 200,
              color: 'var(--tg-theme-text-color)',

              // backgroundColor: 'rgba(215, 215, 215, 0.4)',
              margin: '2px 0',
              padding: '2px 6px 3px',
              borderRadius: '10px',
              fontSize: '10',
              opacity: 0.75,
              display: 'inline-block', // Элемент адаптируется по размеру контента
              // backgroundColor: 'rgba(0, 0, 0, 0.1)', // Добавляем слегка видимый фон

              cursor: 'pointer', // Явно добавляем курсор-указатель

        }}> 

          {indicatorStr}

        </div>}
        

      </>
  );
}

export default ConnectionIndicator;
