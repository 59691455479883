import { useEffect, useRef, useState, useCallback } from 'react';
import { useGameContext } from '../../contexts/GameContext';

export const useCanvasSetup = () => {

  const gameContext = useGameContext();
  const { 
    gameInfoRef, 
    gameIsLoaded, 
    canvasBgRef,
   } = gameContext;

  const [canvasInitialized, setCanvasInitialized] = useState(null);

  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const tempCanvasRef = useRef(null);
  const tempContextRef = useRef(null);
  const bgCanvasRef = useRef(null);
  const bgContextRef = useRef(null);
  const softCanvasRef = useRef(document.createElement('canvas'));
  const softContextRef = softCanvasRef.current.getContext('2d');

  const basicSizeRef = useRef(Math.min(window.innerWidth, window.innerHeight * 0.7));
  const visibleSizeRef = useRef(basicSizeRef.current);
  const canvasStyleSizeRef = useRef(basicSizeRef.current * 0.98);
  const canvasSideRef = useRef(800);

  const canvasStyleDimensionsRef = useRef({
    width: basicSizeRef.current * 0.98, 
    height: basicSizeRef.current * 0.98,
  });
  const canvasDimensionsRef = useRef({
    width: 800, 
    height: 800,
  });

  const canvasScaleRef = useRef(canvasStyleDimensionsRef.current.width / canvasDimensionsRef.current.width);
  
  const canvasRectRef = useRef(null);
  const initializeCanvasSize = (thisCanvasRef, thisContexRef, more = {}) => {

    const game = gameInfoRef.current;

    if (!thisCanvasRef.current) { return; }
    if (!game) { return; }
    
    game.settings.orientation = game.presetSize ? getOrientation(game.presetSize) : game.settings.orientation;

    if (gameInfoRef.current && gameInfoRef.current.mode !== 'sprint') {

      if (game.presetSize) {
        canvasDimensionsRef.current = game.presetSize;
      } else {

        if (game.settings.orientation === 'vertical') {
          if (game.settings.highRes) {
            canvasDimensionsRef.current = gameInfoRef.current.dimensions?.height === 1920 ? {
              width: 1280,
              height: 1920,
            } : {
              width: 800,
              height: 1200
            };
          } else {
            canvasDimensionsRef.current = {
              width: 800,
              height: 1200
            };
          }
        } else if (game.settings.orientation === 'horizontal') {
          if (game.settings.highRes) {
            canvasDimensionsRef.current = gameInfoRef.current.dimensions?.width === 1920 ? {
              width: 1920,
              height: 1280,
            } : {
              width: 1200,
              height: 800
            };
          } else {
            canvasDimensionsRef.current = {
              width: 1200,
              height: 800
            };
            }
        } else {
          if (game.settings.highRes) {
            canvasDimensionsRef.current = {
              width: 1280,
              height: 1280
            };
          } else {
            canvasDimensionsRef.current = {
              width: 800,
              height: 800
            };
          }
        }

      }

      const aspectRation = canvasDimensionsRef.current.height / canvasDimensionsRef.current.width;

      if (game.settings.orientation === 'vertical') {

        basicSizeRef.current = Math.min(window.innerWidth * aspectRation, window.innerHeight * 0.71);

        canvasStyleDimensionsRef.current = {
          width: basicSizeRef.current * 0.98 / aspectRation,
          height: basicSizeRef.current * 0.98
        };

      } else if (game.settings.orientation === 'horizontal') {

        basicSizeRef.current = Math.min(window.innerWidth, window.innerHeight * 0.71);

        canvasStyleDimensionsRef.current = {
          width: basicSizeRef.current * 0.98,
          height: basicSizeRef.current * 0.98 * aspectRation
        };
      } 

      canvasScaleRef.current = Math.min(canvasStyleDimensionsRef.current.width / canvasDimensionsRef.current.width, canvasStyleDimensionsRef.current.height / canvasDimensionsRef.current.height);
    }

    const { fill } = more;
    const canvas = thisCanvasRef.current;

    canvas.width = canvasDimensionsRef.current.width;
    canvas.height = canvasDimensionsRef.current.height;
    
    canvas.style.width = `${canvasStyleDimensionsRef.current.width}px`;
    canvas.style.height = `${canvasStyleDimensionsRef.current.height}px`;

    const context = canvas.getContext('2d');
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.fillStyle = canvasBgRef.current;
    if (fill) {
      context.fillRect(0, 0, canvas.width, canvas.height);
    }
    thisContexRef.current = context;
  };

  const getOrientation = (presetSize) =>{
    if (!presetSize) { return 'square'; }
    return presetSize.height > presetSize.width ? 'vertical' :
    presetSize.width > presetSize.height ? 'horizontal' :
    'square';
  }
  
  const updateCanvases = () => {

    initializeCanvasSize(canvasRef,  contextRef, { fill: true });
    canvasRectRef.current = canvasRef.current.getBoundingClientRect();
    initializeCanvasSize(bgCanvasRef, bgContextRef,);
    initializeCanvasSize(tempCanvasRef, tempContextRef,);
    initializeCanvasSize(softCanvasRef, softContextRef,);

    setCanvasInitialized(true);

  }

  useEffect(() => {
    if (gameIsLoaded) {
      updateCanvases();
    }
  }, [gameIsLoaded]);

  return {
    canvasInitialized,
    visibleSizeRef,
    canvasStyleSizeRef,
    canvasSideRef,

    canvasStyleDimensionsRef,
    canvasDimensionsRef,

    canvasScaleRef,
    canvasRectRef,

    canvasRef,
    contextRef,
    tempCanvasRef,
    tempContextRef,
    bgCanvasRef,
    bgContextRef,
    softCanvasRef,
    softContextRef,

    updateCanvases,
  };
};

