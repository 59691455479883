// useRedraw.js
import { useCallback, useEffect, useRef } from 'react';
import chroma from 'chroma-js';

import { useGameContext  } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';

import { showZoomed, freeMemory } from './imageCached';
import { cropCanvas } from './cropCanvas';

import { createFill } from './brushes/filler';
import { drawPlainMarkerStroke } from './brushes/marker';

import { drawDashedStroke } from './brushes/dashed';
import { drawOutlinedStroke } from './brushes/outlined';
import { drawSprayStroke } from './brushes/spray';
import { drawSprayNoBlurStroke } from './brushes/sprayNoBlur';
import { drawFeatherStroke } from './brushes/feather';
import { drawPencilStroke } from './brushes/pencil';
import { drawOilStroke } from './brushes/oil';
import { drawWatercolorStroke } from './brushes/watercolor';
import { drawBristleStroke } from './brushes/bristle';
import { drawRembrandtStroke } from './brushes/rembrandt';
import { drawBlurStroke } from './brushes/blur';
import { drawEffectStroke } from './brushes/effect';
import { drawSparkleStroke } from './brushes/sparkle';
import { drawNeonStroke } from './brushes/neon';
import { drawTextureStroke } from './brushes/texture';

export const useRedraw = () => {


  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();

  const {
    imageCache,
    imageDataToPNG,

    activeUserIdRef,
    gameInfoRef,
    userSetsRef,

    strokesAreLoaded,
    strokesLoadTimeRef,
    lastStrokeTimeRef,

    isAppleDevice,

    forceRender,
    showTemporaryHint,

    canvasBgRef,
    transparentBgRef,
  } = gameContext;

  const {
    canvasDimensionsRef,

    canvasRef, contextRef, 
    softCanvasRef, softContextRef,
    bgCanvasRef, bgContextRef,

    canvasScaleRef,
  
    zoomFactor, 
    zoomFactorRef,
    maxZoomFactorRef,
    isDrawingRef,

    isRenderingStrokesRef,
    lastStrokeRenderTimeRef,
  
  } = canvasContext;

  const {
    lineWidth,
    setVisibleLineWidth,
  } = brushContext;

  const {
    userStrokesRef,
    redrawer,
  } = HistoryContext;

  const gameSettings = gameInfoRef.current?.settings;

  function drawBackground({ context, color }) {
    context.save();
    context.fillStyle = color;
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    context.restore();
  }

  async function drawStroke(stroke, context) {

    stroke = {...stroke};

    if (stroke.points) {
      stroke.points = stroke.points.map(point=>{
        if (Array.isArray(point)) {return {x: point[0], y: point[1]}}
        else {return point}
      });
    }

    const {
      brush,
      effect,
      sets = {},
    } = stroke;

    const softness = sets?.softness || stroke.softness || 0;

    const params =  { 
      isApple: isAppleDevice(),
    };

    context.save();
    context.globalCompositeOperation = stroke.sets?.composition || 'source-over';

    if (transparentBgRef.current) {
      if (stroke.tool === 'eraser') {
        context.globalCompositeOperation = 'destination-out';
      } else if (transparentBgRef.current && chroma(stroke.color).alpha() === 0) {
        stroke.color = '#ffffffff';
        context.globalCompositeOperation = 'destination-out';
      }
    }

    if (['feather', 'ink'].includes(brush)) {
      await drawFeatherStroke(stroke, context, params);
    } else if (brush === 'pencil') {
      await drawPencilStroke(stroke, context, params);
    } else if (brush === 'oil') {
      await drawOilStroke(stroke, context, params);
    } else if (brush === 'watercolor') {
      await drawWatercolorStroke(stroke, context, params);
    } else if (brush === 'bristle') {
      await drawBristleStroke(stroke, context, params);
    } else if (brush === 'rembrandt') {
      await drawRembrandtStroke(stroke, context, params);
    } else if (effect === 'blur' || brush === 'blur') {
      await drawBlurStroke(stroke, context, params);
    } else if (effect === 'texture') {
      await drawTextureStroke(stroke, context, params);
    } else if (effect === 'noise' || brush === 'noise') {
      await drawEffectStroke(Object.assign({}, stroke, {
        effect: 'noise',
      }), context, params);
    } else if (effect) {
      await drawEffectStroke(stroke, context, params);
    } else if (brush === 'dashed') {
      await drawDashedStroke(stroke, context, params);
    } else if (brush === 'outlined') {
      await drawOutlinedStroke(stroke, context, params);
    } else if (brush === 'neon') {
      // await drawInnerShadowStroke(stroke, context, params);
      await drawNeonStroke(stroke, context, params);
    } else if (brush === 'sparkle') {
      await drawSparkleStroke(stroke, context, params);
    } else if (brush === 'test') {
      // await drawNeonStroke(stroke, context, params);
      // await drawSparkleStroke(stroke, context, params);
    } else if (brush === 'spray' || softness > 0) {
      if (isAppleDevice() && sets.appleSprayFixFinal) {
        // await drawSprayStroke(stroke, context, softContextRef.current, params)
        await drawSprayNoBlurStroke(stroke, context, softContextRef.current)
      } else {
        await drawSprayStroke(stroke, context, softContextRef.current, params)
      }
    } else {
      await drawPlainMarkerStroke(stroke, context, params);
    }

    context.restore();


  }
  
  const redrawCanvas = async () => {

    const context = contextRef.current;
    const canvas = canvasRef.current;
    if (!context || !canvas) return;

    if (isDrawingRef.current) { return; }

    if (
      isRenderingStrokesRef.current 
      // || lastStrokeRenderTimeRef.current > Date.now() - 5000
      ) {return;}

    isRenderingStrokesRef.current = true;
    const renderStartTime = Date.now();
    let lastHintTime = 0;
  
    context.save(); // Сохраняем текущее состояние контекста
    context.setTransform(1, 0, 0, 1, 0, 0); // Сбрасываем трансформацию
    context.clearRect(0, 0, canvas.width, canvas.height); // Очищаем канвас
    context.restore(); // Восстанавливаем состояние контекста
    
    context.fillStyle = canvasBgRef.current;
    context.fillRect(0, 0, canvas.width, canvas.height);
  
    const activeStrokes = prepareActiveStrokes();
    const needToRender = activeStrokes.filter(stroke => !stroke.cancelled && !stroke.hidden);
    
    let hasSpecialBrush = false;
    maxZoomFactorRef.current = 20;

    let i = 0;
    for (let stroke of needToRender) {

      lastStrokeRenderTimeRef.current = Date.now();

      if (imageCache.current.get(stroke.time)) {
        showZoomed({
          stroke,
          canvas: canvasRef.current, 
          imageCache,
          bgColor: canvasBgRef.current,
        })
      } else if (stroke.type === 'background') {
        drawBackground({ context, color: stroke.color })
      } else if (stroke.type === 'fill') {
        makeFill ({
          context: contextRef.current,
          stroke, 
        })
      } else if (stroke.type === 'stroke') {

        await drawStroke(stroke, context);

        if (stroke.softness > 0) { hasSpecialBrush = true; }
        if (
          ['feather', 'ink', 'oil', 'pencil', 'blur', 'noise', 'watercolor', 'bristle', 'rembrandt', 'sparkle', 'test', 'neon'].includes(stroke.brush)
          || stroke.effect
          || stroke.gradientColor
          || stroke.points?.[0]?.pressure
          ) { hasSpecialBrush = true;}
      }


      i++;
      const currentTime = Date.now();

      if (currentTime - renderStartTime > 1000) {
        if (currentTime - lastHintTime > 1000) {
          lastHintTime = currentTime;
          showTemporaryHint(`⏳ Loading: ${i} / ${needToRender.length}`, {force: true, duration: 1000});
          setTimeout(() => {  forceRender();  }, 0);
          await new Promise(resolve=>setTimeout(resolve, 300));
        }
      }

    }

    const specialBrushCacheAmount = userSetsRef.current.moreCache ? 2 : 4;

    let amountForCache = hasSpecialBrush ? specialBrushCacheAmount : 50;
    if (needToRender.length >= amountForCache) {
      const lastStroke = needToRender[needToRender.length - 1];
      saveCache (context, lastStroke);
      activeStrokes.forEach(stroke=>{stroke.rendered = true})
    }

    isRenderingStrokesRef.current = false;
    
  }; 


  function prepareActiveStrokes (more = {}) {

    const { strokes } = more;

    const allStrokes = strokes || Object.values(userStrokesRef.current).flat();
    let sortedStrokes = allStrokes.sort((a, b) => a.time - b.time);
    // sortedStrokes = sortedStrokes.filter(stroke => !stroke.cancelled && !stroke.hidden);

    if (gameInfoRef.current?.mode === 'line') {
      const backgrounds = sortedStrokes.filter(stroke=>stroke.type === 'background');
      if (isDrawingRef.current) {return backgrounds}
      else {return sortedStrokes.length ? [...backgrounds, sortedStrokes.pop()] : backgrounds;}
    }

    let clearIndex;
    let cacheIndex;
    // let missedStrokes;

    for (let i = sortedStrokes.length - 1; i >= 0; i--) {

      const stroke = sortedStrokes[i];
      const cachedData = imageCache.current.get(stroke.time);

      if (cachedData) {
        let validCache = isCacheValid(sortedStrokes.slice(0, i), stroke.time);
        if (validCache) {
          cacheIndex = i;
          break;
        } else {
          imageCache.current.delete(stroke.time);
        }
      }

      if (stroke.type === 'clear') {
        clearIndex = i;
        break;
      }

    }

    let needToRender;
    if (cacheIndex) {
      needToRender = sortedStrokes.slice(cacheIndex);
    } else if (clearIndex) {
      needToRender = sortedStrokes.slice(clearIndex);
    } else {
      needToRender = sortedStrokes;
    }

    if (needToRender.length) {
      lastStrokeTimeRef.current = needToRender[needToRender.length - 1].time || 0;
    }

    return needToRender;

  }

  function isCacheValid (strokes, time) {

    if (Object.values(userStrokesRef.current).length === 1 && userStrokesRef.current[activeUserIdRef.current]) { return true; }

    // const notRendered = strokes.find(stroke=> !stroke.rendered && stroke.time < time);
    const notRendered = strokes.find(stroke=> !stroke.rendered && stroke.time < time && stroke.time > strokesLoadTimeRef.current);
    return !notRendered;
  }


  function makeFill ({context, stroke}) {

        try {
          createFill(
            stroke,
            context, 
            imageCache,
          )
          saveCache(context, stroke)
          showZoomed({
            stroke,
            canvas: context.canvas, 
            imageCache,
            bgColor: canvasBgRef.current,
          })
        } catch (e) {
          console.error(e);
        }
    
  }


  function saveCache (context, lastStroke) {

    if (!lastStroke) { return; }

    const canvas = context.canvas;
    let imageData = imageCache.current.get(lastStroke.time);
    if (!imageData) {
      imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      imageCache.current.set(lastStroke.time, imageData)
    }
    freeMemory ({imageCache, lastStrokeTime: lastStroke.time});

    return { imageData, lastStroke }
    
  }

  async function render (pref = {}) {

    try {

      const needToRender = prepareActiveStrokes()
      .filter(stroke => !stroke.cancelled && !stroke.hidden);

      if (!needToRender[0]) { return { empty: true }; }

      const { lastStroke, imageData } = await prepareRenderData(needToRender, pref);
      const imagePng = imageDataToPNG(imageData);
      return {
        lastStroke,
        imagePng,
      }

    } catch (error) {
      return { error: {
        message: error.message,
        stack: error.stack,
      },}
    }
    
  }

  async function prepareRenderData (strokes, pref = {}) {

    const renderCanvas = document.createElement('canvas');
    renderCanvas.width = canvasDimensionsRef.current.width;
    renderCanvas.height = canvasDimensionsRef.current.height;
    const renderContext = renderCanvas.getContext('2d');

    renderContext.fillStyle = canvasBgRef.current;
    renderContext.fillRect(0, 0, renderCanvas.width, renderCanvas.height);
  
    // Рендерим штрихи с учетом масштаба и смещения
    for (let stroke of strokes) {

      if (imageCache.current.get(stroke.time)) {
        showZoomed({
          stroke,
          canvas: renderCanvas, 
          imageCache,
          bgColor: canvasBgRef.current,
        })
      } else if (stroke.type === 'background') {
        drawBackground({ context: renderContext, color: stroke.color })
      } else if (stroke.type === 'fill') {
        createFill({
          stroke,
          canvas: renderCanvas, 
          imageCache,
        })
      } else if (stroke.type === 'stroke') {
        await drawStroke(stroke, renderContext);
      }

    }

    const lastStroke = strokes[strokes.length - 1];

    let imageData;
    let cropCoordinates;

    if (pref.crop) {
      
      const cropped = cropCanvas(renderCanvas);
      const croppedCanvas = cropped.croppedCanvas;
      cropCoordinates = cropped.cropCoordinates;
      const croppedContext  = croppedCanvas.getContext('2d');
      imageData = croppedContext.getImageData(0, 0, croppedCanvas.width, croppedCanvas.height);

    } else if (gameInfoRef.current.background && !pref.noBackground) {
      imageData = combinedImageData (renderCanvas, bgCanvasRef.current);
    } else {
      imageData = saveCache (renderContext, lastStroke).imageData;
    }

    return {
      lastStroke,
      imageData,
      cropCoordinates,
    }

  };

  function croppedImageData(canvas) {

  }

  function combinedImageData(canvas, backgroundCanvas) {
    // Создаём временный canvas
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = canvas.width;
    tempCanvas.height = canvas.height;
    const tempContext = tempCanvas.getContext('2d');
    
    // Рисуем сначала фон, потом передний план
    tempContext.drawImage(backgroundCanvas, 0, 0);
    tempContext.drawImage(canvas, 0, 0);

    return tempContext.getImageData(0, 0, canvas.width, canvas.height);
  }




  useEffect(() => {
    if (redrawer) {redrawCanvas();}
  }, [redrawer]); 


  useEffect(() => {
    setVisibleLineWidth(lineWidth * zoomFactor * canvasScaleRef.current);
  }, [lineWidth, zoomFactor]);


  return {
    drawStroke,
    redrawCanvas,
    render,
    prepareActiveStrokes,
  }
};

