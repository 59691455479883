// SettingsOverlay.js

import React, { useState, useRef } from 'react';
import { HexAlphaColorPicker } from "react-colorful";
import { FaCog, FaTabletAlt, FaRegClock, FaExpand, FaDownload } from 'react-icons/fa';
import { FaGavel } from 'react-icons/fa6';
import { TbSticker, TbPhoto, TbMail } from 'react-icons/tb';

import './DrawingSetsOverlay.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';
import { SquareIcon, HorizontalIcon, VerticalIcon } from './OrientationIcons';

import { handleFullScreen, handlePetAvatar, handleCreateSticker, handleSaveAccessory, handleAuction } from './menuHandlers';


function DrawingSetsOverlay({
  gameContext,
  brushContext,
}) {

  const { 
    drawMethodsRef,
    gameId,
    telegramUserId,
    gameInfoRef, 
    userSetsRef, 

    openMenu, closeMenu,
    saveUserSets, 
    saveGameSets, 
    hostAction,
    delayPremiumMenu,
    showPopupMessage,
    showTemporaryHint,
    isAppleDevice,

   } = gameContext;
  const { 
    color, 
   } = brushContext;

  const drawMethods = drawMethodsRef.current;
  const { render } = drawMethods;

  const { t } = useTranslation();

  const game = gameInfoRef.current;

  const [userColorView, setUserColorView] = useState(false);
  const [viewedColor, setViewedColor] = useState(color);
  const setParamRef = useRef(null);

  let gameSettings = [];
  if (!['horizontal', 'vertical'].includes(gameInfoRef.current?.settings?.orientation)) {
    gameSettings.push({ key: 'highRes', label: t('settings.high_res') },)
  }

  const handleGameSetCheckbox = (key) => {

    const gameSettings = gameInfoRef.current?.settings;
    // saveUserSets({ [key]: !gameSettings[key] })
    saveGameSets({ [key]: !gameSettings[key] }, (result)=>{
      window.location.reload();
    });

  };

  const moderEdit = () => {
    saveUserSets({ 
      moderEditing: userSetsRef.current.moderEditing === gameId ? null : gameId }, (result)=>{
      window.location.reload();
    })
  };

  const handleOrientationChange = (newOrientation) => {
    saveUserSets({ orientation: newOrientation });

    const gameSets = { orientation: newOrientation };
    saveGameSets(gameSets, (result)=>{
      window.location.reload();
    });
  };


  const OrientationSelector = () => (
    <div className="orientation-options">
      {[
        { value: 'square', Icon: SquareIcon },
        { value: 'horizontal', Icon: HorizontalIcon },
        { value: 'vertical', Icon: VerticalIcon },
      ].map(({ value, Icon }) => (
        <button
          key={value}
          className={`orientation-option ${(gameInfoRef.current.settings.orientation || 'square') === value ? 'active' : ''}`}
          onClick={() => handleOrientationChange(value)}
        >
          <Icon />
        </button>
      ))}
    </div>
  );


  const handleSaveImage = async () => {

    const popupParams = {
      title: t('save.title'),
      message: t('save.text'),
      buttons: [
        // { id: 'cancel', type: 'cancel', text: t('save.button_cancel') },

        isAppleDevice() ? 
        { id: 'cancel', type: 'cancel', text: t('save.button_cancel') } :
        { id: 'device', type: 'default', text: t('save.button_device') },
        { id: 'pm', type: 'default', text: t('save.button_pm') },
      ],
    };

    popupParams.callback = (buttonId) => {
      if (buttonId === 'device') { handleSaveHere() }
      if (buttonId === 'pm') {
        window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')

        hostAction('savePng', null, (result)=>{
          window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')

          if (result) {
            showPopupMessage({
              title: t('was_saved_pm.success_title'),
              message: t('was_saved_pm.success_message'),
            });
          } else {
            showPopupMessage({
              title: t('was_saved.error_title'),
              message: t('was_saved.error_message'),
            });
          }

        });
      }
    }
    showPopupMessage(popupParams)

  }

  const handleSaveHere = async () => {
    try {
      const renderResult = await render();
      
      if (renderResult.error) {
        console.error("Render error:", renderResult.error);
        showPopupMessage({
          title: t('was_saved.error_title'),
          message: t('was_saved.error_message') + JSON.stringify(renderResult.error),
        });
        return;
      }

      if (renderResult.empty) {
        showPopupMessage({
          title: t('was_saved.error_title'),
          message: t('was_saved.error_message') + 'empty',
        });
        return;
      }

      const { imagePng } = renderResult;
      let blob;

      // Check the type of imagePng and create the appropriate Blob
      if (imagePng instanceof Blob) {
        blob = imagePng;
      } else if (imagePng instanceof ArrayBuffer) {
        blob = new Blob([new Uint8Array(imagePng)], { type: 'image/png' });
      } else if (typeof imagePng === 'string' && imagePng.startsWith('data:image/png;base64,')) {
        const base64Data = imagePng.split(',')[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        blob = new Blob([byteArray], { type: 'image/png' });
      } else {
        // If it's none of the above, assume it's raw binary data
        blob = new Blob([imagePng], { type: 'image/png' });
      }
      
      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `croco_${game.word}.png`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      showPopupMessage({
        title: t('was_saved.success_title'),
        message: t('was_saved.success_message') + a.download,
      });
    } catch (error) {
      console.error("Save PNG error:", error);
      showPopupMessage({
        title: t('was_saved.error_title'),
        message: t('was_saved.error_message') + JSON.stringify(error),
      });
    }
  };


  return (
    <>
      <div className="overlay-background" onClick={() => closeMenu()}></div>
      <div className="drawing-menu" style = {{opacity: userColorView ? 0 : 1}}>

        <div className="drawing-menu-settings">
          <span className="settings-title">{t('drawing.title')}</span>
          {OrientationSelector()}

          {gameSettings.map((setting) => (
            <div key={setting.key} className="drawing-menu-item">
              <label className="settings-label" style = {{
                opacity: gameInfoRef.current.settings[setting.key] ? 1 : 0.3
              }}>
                <input
                  type="checkbox"
                  className='drawing-menu-item-checkbox'
                  checked={gameInfoRef.current.settings[setting.key]}
                  onChange={() => handleGameSetCheckbox(setting.key)}
                  style={{
                    height: '20px',
                  }}
                />
                <span>{setting.label}</span>
              </label>
            </div>
          ))}

          <div className="drawing-menu-item">
            <label className="settings-label" style = {{
              opacity: game.settings.plus18 ? 1 : 0.3
            }}>
              <input
                type="checkbox"
                className='drawing-menu-item-checkbox'
                checked={game.settings.plus18}
                onChange={() => { saveGameSets({ plus18: !game.settings.plus18 }) }}
                style={{ height: '20px', }}
              />
              <span>{ t('settings.plus18') }</span>
            </label>
          </div>

          <div className="drawing-menu-item">
            <label className="settings-label" style = {{
              opacity: game.settings.transparentBg ? 1 : 0.3
            }}>
              <input
                type="checkbox"
                className='drawing-menu-item-checkbox'
                checked={game.settings.transparentBg}
                onChange={() => { saveGameSets({ transparentBg: !game.settings.transparentBg }, ()=>window.location.reload()) }}
                style={{ height: '20px', }}
              />
              <span>{ t('settings.transparent_bg') }</span>
            </label>
          </div>

          <div className="drawing-menu-item" onClick={()=>{handleFullScreen({ t, hostAction, showPopupMessage, showTemporaryHint })}}>
            <label className="settings-label">
            <FaExpand className="settings-label-icon"/>
              <span>{t('fullscreen.button_start')}</span>
            </label>
          </div>

          { }


          <div className="drawing-menu-item" onClick={
            ()=>{hostAction('sendInPm', null, (result)=>{
              result && window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
            })}
          }>
            <label className="settings-label">
              <TbMail className="settings-label-icon"/>
              <span>{t('settings.send_in_pm')}</span>
            </label>
          </div>

          { gameInfoRef.current.accessory ? 
            (
              <div className="drawing-menu-item" onClick={()=>{handleSaveAccessory({ t, hostAction, showPopupMessage })}}>
                <label className="settings-label">
                <FaDownload className="settings-label-icon"/>
                  <span>{t('pet.accessory.button_save')}</span>
                </label>
              </div>
            ) : (
              <>
                <div className="drawing-menu-item" onClick={handleSaveImage}>
                  <label className="settings-label">
                  <FaDownload className="settings-label-icon"/>
                    <span>{t('save.button_save')}</span>
                  </label>
                </div>

                { !gameInfoRef.current.clone && (
                    <div className="drawing-menu-item" onClick={()=>{handlePetAvatar({ t, hostAction, showPopupMessage })}}>
                      <label className="settings-label">
                      <TbPhoto className="settings-label-icon"/>
                        <span>{t('pet.avatar.button_set')}</span>
                      </label>
                    </div>
                )}

                <div className="drawing-menu-item" onClick={()=>{handleCreateSticker({ t, hostAction, showPopupMessage })}}>
                <label className="settings-label">
                <TbSticker className="settings-label-icon"/>
                  <span>{t('sticker.button_create')}</span>
                </label>
                </div>
              </>
              )
            }

            { !gameInfoRef.current.accessory && !game.clone && (
              <div className="drawing-menu-item" onClick={()=>{handleAuction({ t, hostAction, showPopupMessage, game })}}>
              <label className="settings-label">
              <FaGavel className="settings-label-icon"/>
                <span>{t('auction.button_apply')}</span>
              </label>
              </div>
            )}

        </div>

        {
          userSetsRef.current.moder && (
            <button className="wide-button" onClick={moderEdit}>
              {t('settings.moderate')}
            </button>
          )
        }

        <button className="wide-button" onClick={() => {
          closeMenu();
          delayPremiumMenu('menu');
        }}>
          {t('settings.premium')}
        </button>

        <button className="gray-button" onClick={()=>{ openMenu('settings', true); }}>
          <FaCog style={{ marginRight: '8px', marginBottom: '3px', verticalAlign: 'middle'}} />
          {t('settings.title')}
        </button>

        <button className="gray-button" onClick={() => closeMenu()}>
          {t('settings.back')}
        </button>


      </div>


    </>
  );
}

export default DrawingSetsOverlay;
