// watercolor.js
import chroma from 'chroma-js';
import { applyPixiBlur } from './pixi/pixiBlur';
import { drawPressureStroke } from './helpers/pressure';
import { 
  duplicateCanvasContext,
  createStrokeMask, 
  applyTextureByStrokeMask,
  drawTexturedStroke, 
  drawNoTextureStroke,
} from './helpers/texturize';
import { 
  newCanvas,
} from './helpers/canvas';

import { getTexture, brushDefaults } from './helpers/brushLoader';
const defaultBrushSettings = brushDefaults.watercolor;

export async function drawWatercolorStroke(stroke, context, params) {
  let {
    color,
    gradientColor,
    lineWidth,
    points,
    time,
    sets = {},
  } = stroke;

  if (points.length === 0) return;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const {
    spreading,
    outline,
    textureOn,
    texture,
    textureScale = 1,
    waterBlurSize = 15,
    waterBlurAlpha = 0.8,
  } = brushSettings;

  const textureName = texture || 'waterAi';
  
  let watercolorTexture = await getTexture('watercolor', textureName);
  let watercolorTextureWhite = await getTexture('watercolor', textureName, 'light');

  const hasBlur = brushSettings.waterBlurSize && brushSettings.waterBlurAlpha;

  const cloneContext = duplicateCanvasContext(context);
  const chromaColor = chroma(color);
  const originalAlpha = chromaColor.alpha();
  const outlineColor = chromaColor.darken(1).hex();

  const newAlpha = originalAlpha * brushSettings.opacity;

  color = chromaColor.alpha(1).hex();

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = cloneContext.canvas.width;
  bufferCanvas.height = cloneContext.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.lineCap = 'round';
  bufferCtx.lineJoin = 'round';

  bufferCtx.globalCompositeOperation = 'source-over';

  const strokeMaskCtx = createStrokeMask({
    points,
    lineWidth,
    color,
    time,
    brushSettings,
  }, cloneContext, drawStroke);

  if (watercolorTexture) {
    let strokeMaskForBlurCtx = createStrokeMask({
      points,
      lineWidth,
      color,
      time,
      brushSettings,
    }, cloneContext, drawStroke);

    if (hasBlur) {
      await applyBlurAlongPath({
        context: cloneContext, 
        maskCanvas: strokeMaskForBlurCtx.canvas, 
        lineWidth, 
        originalAlpha, 
        isApple: false,
        waterBlurSize,
        waterBlurAlpha,
      });
    }

    if (textureOn) {
      await drawTexturedStroke({
        color,
        gradientColor,
        points,
        lineWidth,
        bufferCtx,
        strokeMaskCtx,
        texture: watercolorTexture,
        textureScale,
        composition: 'source-over',
      });

      applyTextureByStrokeMask({
        context: cloneContext,
        bufferCtx,
        strokeMaskCtx: strokeMaskForBlurCtx,
        texture: watercolorTextureWhite,
        color: 'white',
        opacity: 0.2,
        textureScale,
        composition: 'luminosity',
      });
    } else {
      await drawNoTextureStroke({
        color,
        gradientColor,
        points,
        lineWidth,
        texture: watercolorTexture,
        textureScale,
        strokeMaskCtx,
        context: bufferCtx,
      });
    }
  }

  cloneContext.globalAlpha = newAlpha;
  cloneContext.drawImage(bufferCanvas, 0, 0);
  cloneContext.globalAlpha = 1;

  context.drawImage(cloneContext.canvas, 0, 0);

  if (outline) {
    drawOutline({
      points,
      lineWidth,
      color: outlineColor,
      time,
      brushSettings,
    }, context);
  }
}

function drawStroke({
  points,
  lineWidth,
  color,
  offset = { x: 0, y: 0 },
  time,
  brushSettings,
}, context) {
  if (brushSettings.spreading) { lineWidth *= 0.82; }

  const mainStroke = {
    points, 
    lineWidth, 
    color, 
    offset, 
    time,
    tapering: false,
    jitterAmount: brushSettings.spreading ? lineWidth / 10 : 0,
  };

  if (points[0].pressure) {
    drawPressureStroke(mainStroke, context, { 
      ...brushSettings, 
      pressureOpacity: brushSettings.spreading ? false : brushSettings.pressureOpacity,
      squareBrush: false,
     })
  } else {
    drawStrokeLine(mainStroke, context);
  }

  if (brushSettings.spreading) {
    let radius = lineWidth / 2.2;
    let lines = 30;
    let offsets = createCircleOffsets(radius, offset, lines, time);
    let adjustedPoints = addEdgePoints(points, 2);

    offsets.forEach((offset, i) => {
      drawStrokeLine({
        points: adjustedPoints, 
        lineWidth: lineWidth / 5, 
        color, 
        offset, 
        jitterAmount: lineWidth / 6,
        time: time + i,
      }, context);
    });
  }
}

function drawOutline(stroke, context) {
  const {
    outlineSize = 1,
    outlineOpacity = 1,
    spreading,
  } = stroke.brushSettings;

  const strokeMaskCtx = newCanvas(context);
  strokeMaskCtx.lineCap = spreading ? 'butt' : 'round';
  strokeMaskCtx.lineJoin = 'round';

  drawStroke({
    ...stroke, 
    lineWidth: stroke.lineWidth + outlineSize / 2,
  }, strokeMaskCtx);

  strokeMaskCtx.globalCompositeOperation = 'destination-out';

  drawStroke({
    ...stroke, 
    color: 'black', 
    lineWidth: stroke.lineWidth - outlineSize / 2,
  }, strokeMaskCtx);

  context.globalAlpha = outlineOpacity;
  context.drawImage(strokeMaskCtx.canvas, 0, 0);
}

function createCircleOffsets(radius, baseOffset = { x: 0, y: 0 }, numPoints, seed) {
  const offsets = [];
  const round = (num) => Math.round(num * 1000) / 1000;

  for (let i = 0; i < numPoints; i++) {
    const baseAngle = (i * 2 * Math.PI) / numPoints;
    const maxDeviation = Math.PI / numPoints / 4;
    const angle = baseAngle + (seededRandom(seed + i) * 2 - 1) * maxDeviation;
    const radiusVariation = 0.05;
    const adjustedRadius = radius * (1 + (seededRandom(seed + i + 1) * 2 - 1) * radiusVariation);
    const x = round(baseOffset.x + adjustedRadius * Math.cos(angle));
    const y = round(baseOffset.y + adjustedRadius * Math.sin(angle));
    offsets.push({ x, y });
  }

  return offsets;
}

function addEdgePoints(points, amount) {
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];
  const startPoints = Array(amount).fill(firstPoint);
  const endPoints = Array(amount).fill(lastPoint);
  const adjustedPoints = [...startPoints, ...points, ...endPoints];
  return adjustedPoints;
}

function drawStrokeLine({
  points,
  lineWidth,
  color,
  offset = { x: 0, y: 0 },
  time,
  tapering,
  jitterAmount,
}, context) {
  if (points.length === 1) {
    points = Array(5).fill(points[0]);
  }

  if (jitterAmount) {
    points = addWatercolorEffectToPoints(points, lineWidth, jitterAmount, time);
  }

  const stroke = {
    points, 
    lineWidth, 
    color, 
    offset, 
  };

  if (points.length === 1) {
    return drawPoint(stroke, context);
  } else {
    return drawPlainStroke(stroke, context);
  }
}

function countPointOffset (point, offset) {

  let currentOffset = { ...offset }
  if (point.pressure) { 
    currentOffset.x *= point.pressure; 
    currentOffset.y *= point.pressure;
   }
  return currentOffset;
  
}

function drawPlainStroke({
  points, lineWidth, color, offset,
}, context) {

  context.strokeStyle = color;
  context.lineWidth = lineWidth;

  context.beginPath();

  const startPoint = points[0];
  const startOffset = countPointOffset(startPoint, offset)

  context.moveTo(startPoint.x + startOffset.x, startPoint.y + startOffset.y);

  for (let i = 1; i < points.length; i++) {
    const nextPoint = points[i - 1];
    const currentPoint = points[i];

    let currentOffset = countPointOffset(currentPoint, offset)

    if (currentPoint.pressure) {
      // context.lineWidth *= currentPoint.pressure;
    }

    const midPoint = {
      x: (nextPoint.x + currentPoint.x) / 2,
      y: (nextPoint.y + currentPoint.y) / 2,
    };
    context.quadraticCurveTo(
      nextPoint.x + currentOffset.x,
      nextPoint.y + currentOffset.y,
      midPoint.x + currentOffset.x,
      midPoint.y + currentOffset.y
    );
  }

  const lastPoint = points[points.length - 1];
  const lastOffset = countPointOffset(lastPoint, offset)

  context.lineTo(
    lastPoint.x + lastOffset.x, 
    lastPoint.y + lastOffset.y
  );
  context.stroke();
}

function drawPoint({
  points, lineWidth, color, offset,
}, context) {
  const point = points[0];

  if (point.pressure) { offset.x *= point.pressure; offset.y *= point.pressure }

  context.beginPath();
  context.arc(
    point.x + offset.x, 
    point.y + offset.y, 
    lineWidth / 2, 
    0, 
    Math.PI * 2
  );
  context.fillStyle = color;
  context.fill();
}

function addWatercolorEffectToPoints(points, lineWidth, jitterAmount, time) {
  if (points.length <= 2) { return points; }

  const watercolorPoints = [];
  jitterAmount = jitterAmount || Math.max(2, lineWidth / 10);

  points.forEach((point, i) => {

    const currentJitter = point.pressure ? 
    jitterAmount * point.pressure + (1 - point.pressure)/1.5 : jitterAmount;

    const angle = seededRandom(time + i) * 2 * Math.PI;
    const distance = seededRandom(time + i) * currentJitter;
    const jitterX = distance * Math.cos(angle);
    const jitterY = distance * Math.sin(angle);

    watercolorPoints.push({
      x: point.x + jitterX,
      y: point.y + jitterY,
      pressure: point.pressure,
    });
  });

  return watercolorPoints;
}

function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

async function applyBlurAlongPath({
  context, 
  maskCanvas, 
  lineWidth, 
  originalAlpha, 
  isApple,
  waterBlurSize = 15,
  waterBlurAlpha = 0.8,
}) {
  const maskedCanvas = document.createElement('canvas');
  maskedCanvas.width = context.canvas.width;
  maskedCanvas.height = context.canvas.height;
  const maskedCtx = maskedCanvas.getContext('2d');

  maskedCtx.drawImage(context.canvas, 0, 0);
  maskedCtx.globalCompositeOperation = 'destination-in';
  maskedCtx.drawImage(maskCanvas, 0, 0);

  const blurredCanvas = document.createElement('canvas');
  blurredCanvas.width = context.canvas.width;
  blurredCanvas.height = context.canvas.height;
  const blurredCtx = blurredCanvas.getContext('2d');

  if (isApple) {
    blurredCtx.drawImage(maskedCanvas, 0, 0);
    await applyPixiBlur(blurredCanvas, waterBlurSize);
  } else {
    blurredCtx.filter = `blur(${waterBlurSize}px)`;
    blurredCtx.drawImage(maskedCanvas, 0, 0);
  }

  blurredCtx.globalCompositeOperation = 'destination-in';
  blurredCtx.drawImage(maskCanvas, 0, 0);

  context.globalAlpha = waterBlurAlpha;
  context.globalCompositeOperation = 'source-over';
  context.drawImage(blurredCanvas, 0, 0);
  context.globalAlpha = 1;

  context.globalCompositeOperation = 'source-over';
}
