import { useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;
const SOCKET_SERVER_PORTS = process.env.REACT_APP_SOCKET_PORTS.split(',');

const useWebSocket = ({ 
  gameId, 
  drawingMode,
  socketPortNum,
  
  timeDifferenceRef,
  telegramUserId,
  initData,
  setIsValid,
  }) => {
    
  const [socketStatus, setSocketStatus] = useState('disconnected');
  const [connectionNum, setConnectionNum] = useState(0);
  const socketRef = useRef(null);

  const PORT = SOCKET_SERVER_PORTS[socketPortNum] || SOCKET_SERVER_PORTS[0]; 
  const FULL_SERVER_URL = `${SOCKET_SERVER_URL}:${PORT}`

  useEffect(() => {
    // Функция для инициализации и регистрации слушателей сокета
    function setupSocket() {

      socketRef.current = io(FULL_SERVER_URL, {});
      const socket = socketRef.current;

      socket.on('connect', () => {
        console.log(`Hello, hackers`);
        setSocketStatus(`connected`);
        setConnectionNum((currentNum)=>currentNum + 1);
      });

    }

    setupSocket();

    // Функция очистки
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [gameId]); 


  useEffect(() => {
    if (connectionNum >= 1) {

      const emitTime = Date.now();

      socketRef.current.emit('register', { 
        gameId, 
        drawingMode,
        initData,
        userId: telegramUserId, 
       }, (result)=>{
        setIsValid(result?.verified);

        if (result?.serverTime) {

          const receiveTime = Date.now();
          const socketRTT = receiveTime - emitTime;
          timeDifferenceRef.current = Date.now() - (result?.serverTime - socketRTT / 2);
          window.logPerformance(`Socket RTT:`, `${socketRTT} ms`);
          window.logPerformance(`Time difference:`, `${timeDifferenceRef.current} ms`);

        }

      });
    }
  }, [connectionNum]); 

  return {
    socketRef,
    socketStatus,
    SERVER_URL: SOCKET_SERVER_URL,
    PORT,
  };
};

export default useWebSocket;
