// useConvy.js
import { useContext, useEffect, } from 'react';

import { useGameContext } from '../contexts/GameContext';
import { useDrawingContext  } from '../contexts/DrawingContext';
import { useBrushContext  } from '../contexts/BrushContext';
import { useHistoryContext  } from '../contexts/HistoryContext';

import { useHelpers } from './convy/useHelpers';  // CONVY

import { useRedraw } from './convy/useRedraw'; // CONVY

import { useTool } from './convy/useTool';
import { useZoomPhone } from './convy/useZoomPhone'; // CONVY
import { useZoomPc } from './convy/useZoomPc'; // CONVY

import { useSoftDraw } from './convy/useSoftDraw'; // CONVY

// import { useShapes } from './convy/useShapes'; // CONVY
import { useShapeDraw } from './convy/useShapeDraw'; // CONVY
import { useColor } from './convy/useColor';
import { useHotMouse } from './convy/useHotMouse';
import { usePointer } from './convy/usePointer'; // CONVY
import { useHistory } from './convy/useHistory';
import { useKeyboardHandlers } from './convy/useKeyboardHandlers';

import { useMountHistory } from './connection/useMountHistory';

const useConvy = () => {

  const gameContext = useGameContext();

  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();

  const drawMethods = useHelpers();

  Object.assign(drawMethods, useRedraw());
  Object.assign(drawMethods, useHistory(drawMethods));
  
  Object.assign(drawMethods, useTool(drawMethods));
  Object.assign(drawMethods, useZoomPhone(drawMethods));
  Object.assign(drawMethods, useZoomPc(drawMethods));
  Object.assign(drawMethods, useSoftDraw(drawMethods, gameContext));
  Object.assign(drawMethods, useShapeDraw(drawMethods, gameContext));

  Object.assign(drawMethods, useColor(drawMethods));
  Object.assign(drawMethods, useHotMouse(drawMethods));
  Object.assign(drawMethods, usePointer(drawMethods, gameContext));
  Object.assign(drawMethods, useKeyboardHandlers(drawMethods));

  useMountHistory(drawMethods);

  gameContext.drawMethodsRef.current = drawMethods;

  return {
    canvasContext, 
    brushContext,
    HistoryContext,
    drawMethods
  };
};

export default useConvy;
