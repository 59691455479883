import { fillGradient } from './gradient';
import { drawPlainPoint, drawPlainStroke } from './plain';
import { createOpacityStroke } from './pressureOpacity';

export async function drawPressureStroke (stroke, context, brushSettings) {

  const {
    points,
    color,
    lineWidth,
    gradientColor,
  } = stroke;

  // Создаем отдельный буфер
  const strokeMaskCanvas = document.createElement('canvas');
  strokeMaskCanvas.width = context.canvas.width;
  strokeMaskCanvas.height = context.canvas.height;
  const strokeMaskCtx = strokeMaskCanvas.getContext('2d');

  createPressureStroke (stroke, strokeMaskCtx, brushSettings)
  fillGradient(strokeMaskCtx, points, color, gradientColor, lineWidth)
  // Добавляем текстурированный мазок на буфер
  context.drawImage(strokeMaskCanvas, 0, 0);

}

export function createPressureStroke (stroke, context, brushSettings) {

  const {
    lineWidth,
    increaseLineWidth = 0,
    points,
    isEffect,
  } = stroke;

  const {
    pressureOn,
    pressureOpacity,
    pressureAvailable,
    pressureCoef = 2,
    moreCache,
  } = brushSettings;

  const turnedOn = pressureOn || pressureOpacity;

  if (!pressureAvailable || !turnedOn || !points[0].pressure || isEffect) {
    return drawPlainStroke(stroke, context, brushSettings);
  }
  if (brushSettings.pressureOpacity) {
    return createOpacityStroke(stroke, context, brushSettings);
  }

  if (points.length === 1) {
    return drawPoint(stroke, context, brushSettings);
  }

  context.lineCap = brushSettings.squareBrush ? 'square' : 'round';
  context.lineJoin = brushSettings.squareBrush ? 'bevel' : 'round';
  if (stroke.lineDash) { context.setLineDash(stroke.lineDash); }

  const smoothPoints = interpolatePoints(points, moreCache ? 0.3 : 1);
  const totalPoints = smoothPoints.length;

  context.strokeStyle = 'rgba(255, 255, 255, 1)';

  for (let i = 0; i < totalPoints - 1; i++) {

    const startPoint = smoothPoints[i];
    const endPoint = smoothPoints[i + 1];

    const segmentWidth = lineWidth * (startPoint.pressure + endPoint.pressure) / 2 * pressureCoef;

    if (segmentWidth === 0) { continue; }

    context.beginPath();
    context.moveTo(startPoint.x, startPoint.y);
    context.lineTo(endPoint.x, endPoint.y);
    context.lineWidth = segmentWidth + increaseLineWidth;
    context.stroke();

  }

  
}


export function drawPoint(stroke, bufferCtx, brushSettings) {

  const lineWidth = stroke.lineWidth * (stroke.points[0]?.pressure || 1)
  return drawPlainPoint({
    ...stroke,
    lineWidth,
  }, bufferCtx, brushSettings)

}


export function interpolatePoints(points, interpolationCoef = 1) {
  if (points.length <= 2) return points;

  function distance(p1, p2) {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  let interpolatedPoints = [];

  // Начинаем с первой точки
  interpolatedPoints.push(points[0]);

  for (let i = 1; i < points.length; i++) {
    const prevPoint = points[i - 1];
    const currentPoint = points[i];

    // Вычисляем расстояние между предыдущей и текущей точкой
    const dist = distance(prevPoint, currentPoint);

    // Определяем количество интерполяций на основе расстояния
    const numInterpolations = Math.min(20 * interpolationCoef, Math.round(interpolationCoef * dist));

    // Вычисляем среднюю точку между предыдущей и текущей точкой
    const midPoint = {
      x: (prevPoint.x + currentPoint.x) / 2,
      y: (prevPoint.y + currentPoint.y) / 2,
      pressure: (prevPoint.pressure + currentPoint.pressure) / 2,
    };

    const controlPoint = prevPoint;
    const startPoint = interpolatedPoints[interpolatedPoints.length - 1];
    const endPoint = midPoint;

    // Интерполируем точки по квадратичной кривой Безье
    for (let j = 1; j <= numInterpolations; j++) {
      const t = j / (numInterpolations + 1);

      const x =
        (1 - t) * (1 - t) * startPoint.x +
        2 * (1 - t) * t * controlPoint.x +
        t * t * endPoint.x;
      const y =
        (1 - t) * (1 - t) * startPoint.y +
        2 * (1 - t) * t * controlPoint.y +
        t * t * endPoint.y;
      const pressure =
        (1 - t) * (1 - t) * startPoint.pressure +
        2 * (1 - t) * t * controlPoint.pressure +
        t * t * endPoint.pressure;

      interpolatedPoints.push({ x, y, pressure });
    }

    // Добавляем конечную точку сегмента
    interpolatedPoints.push(endPoint);
  }

  return interpolatedPoints;
}
