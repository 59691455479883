// useGameLoader.js
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';


export const useGameLoader = ({

  socketRef, 
  socketStatus, 
  gameId, 
  drawingMode,
  inBrowser,
  telegramUserId,
  activeUserIdRef,
  moderatorModeRef,
  moderatorIdRef,
  isValid,
  
  setGameInfo,
  setGameIsLoaded,
  gameInfoRef, 
  setCanDraw,
  showPopupMessage,

  setGameError,

  userSetsRef,
  groupSetsRef,
  botSetsRef,

  imageCache,
  updatePngCache,

  canvasBgRef,
  transparentBgRef,
}) => {

    const { i18n, t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const retryCountRef = useRef(0);  // Добавлено для контроля количества попыток
    const autoFullScreenOff = useRef(null);  

    useEffect(() => {

        if (
          isValid
          && !gameInfoRef.current 
          && socketStatus === 'connected' 
          && !isLoading) {

            setIsLoading(true);
            window.logPerformance('Game data loading STARTED')

            loadGameData({
              socket: socketRef.current,
              gameId,
              drawingMode,
              telegramUserId,
              gameInfoRef,
              setGameInfo,
              setIsLoading,

              retryCountRef,
          });
            
        }

    }, [socketStatus, isValid]);


    const autoOpenInBrowser = ()=>{

      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('brw', '1');
      window.open(currentUrl.toString(), '_blank');

      const popupParams = {
        title: t('auto_fullscreen.title'),
        message: t('auto_fullscreen.text'),
        buttons: [
          { id: 'cancel', type: 'cancel', text: t('auto_fullscreen.button_cancel') },
          { id: 'close', type: 'destructive', text: t('auto_fullscreen.button_close') },
        ],
        items: [{ type: 'set', key: 'openBrowser', label: t('settings.open_browser_on_start') }],
      };
  
      popupParams.callback = (buttonId) => {
        if (buttonId === 'cancel') { 
          autoFullScreenOff.current = true;
         }
        if (buttonId === 'close') { 
          window.Telegram.WebApp.close();
         }
      }

      showPopupMessage(popupParams)
      setTimeout(() => {
        if (!autoFullScreenOff.current) {
          window.Telegram.WebApp.close();
        }
      }, 10 * 1000);

    }

    // Функция загрузки данных игры
    const loadGameData = ({
        socket, 
        gameId,
        drawingMode,
        telegramUserId, 

        gameInfoRef,
        setGameInfo,

        retryCountRef,
    }) => {

        if (socket && !isLoading) {

            socket.emit('loadGame', { 
              gameId, 
              drawingMode,
              userId: telegramUserId, 
            }, async (loaded = {}) => {

                window.logPerformance('Game data LOADED')

                let { game, user, group, bot } = loaded;

                setIsLoading(false);
                if (!game) {return;}
                if (game.error) { return setGameError(game) } 

                // Настройки пользователя
                Object.assign(userSetsRef.current, user || {})
                Object.assign(groupSetsRef.current, group || {})
                Object.assign(botSetsRef.current, bot || {})

                game = Object.assign({
                  chat: [],
                  // chat: [
                  //   {author: 'Козлик', text: 'Привет'},
                  //   {author: 'Мартышка', text: 'хай'},
                  //   {author: 'Ути', text: 'Пыр фыр пыр мыр гыр пыр выр мыр'},
                  // ],
                }, game);

                if (game.png) { await updatePngCache(game, imageCache) }
                gameInfoRef.current = game;
                if (userSetsRef.current.moderEditing === gameId) {
                  moderatorModeRef.current = true;
                }

                if (game.settings?.accessory || game.settings?.transparentBg) {
                  transparentBgRef.current = true;
                  canvasBgRef.current = '#00000000';
                } else {
                  canvasBgRef.current = 'white';
                }
                
                setGameInfo(game);

                let ifCanDraw;

                if (game?.notFound) {
                  ifCanDraw = false;
                } else if (game.mode === 'sprint') {
                  if (game.word) {
                    ifCanDraw = true;
                  } else {
                    ifCanDraw = false;
                  }
                } else if (game.board) {
                  ifCanDraw = true;
                } else if (telegramUserId) {
                  if (`${game?.user_key}` === `${telegramUserId}`) {
                    ifCanDraw = true;
                  } else if (game.shared?.includes(`${telegramUserId}`)) {
                    ifCanDraw = true;
                  } else if (userSetsRef.current.moder && moderatorModeRef.current) {
                    moderatorIdRef.current = telegramUserId;
                    activeUserIdRef.current = game.user_key;
                    ifCanDraw = true;
                  }
                }

                if (
                  user.openBrowser
                  && ifCanDraw 
                  && game.mode !== 'sprint' 
                  && !inBrowser
                  ) {
                    autoOpenInBrowser();
                }

                setCanDraw(ifCanDraw);
                i18n.changeLanguage(game?.locale || 'ru');
                setGameIsLoaded(true);
                window.logPerformance('Game data APPLIED')


            });
        }

    };

    return isLoading;  // Можно вернуть isLoading, если хотите отображать индикатор загрузки где-то в UI
};

export default useGameLoader;