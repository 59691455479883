// loadHistory.js
import { useEffect } from 'react';

export const usePremium = ({
  gameInfo,
  gameIsLoaded,
  setPremiumIsLoaded,

  userSetsRef,
  groupSetsRef,
  premiumSubsRef,
  premiumFeaturesRef,
}) => {
  
  const plans = {

 
    user: {

      default: [
        'pipette',
      ],

      artist: [
        'colors',
        'effect',
        'pipette',
        'hints',
        'personoalWord',
        // '',
      ],
      master: [
        'colors',
        'effect',
        'pipette',
        'hints',
        'personoalWord',
  
        'saveColors',
        'brushes',
        // '',
      ],

      moder: [
        'colors',
        'effect',
        'pipette',
        'hints',
        'personoalWord',
  
        'saveColors',
        'brushes',
      ],
    },


    group: {
      workshop: [
        'colors',
        'effect',
        'pipette',
        'hints',
        'personoalWord',

        // '',
      ],
      artschool: [
        'colors',
        'effect',
        'pipette',
        'hints',
        'personoalWord',
  
        'saveColors',
        'brushes',
      ],
  
    },

  }

  useEffect(() => {
    
    if (!gameIsLoaded) { return; }

    let now = Math.max(Date.now(), gameInfo?.created || 0);
    let activeSubs = [];
    let activeFeatures = [];

    let userSettings = userSetsRef.current;
    let groupSettings = groupSetsRef.current;

    activeFeatures = activeFeatures.concat(plans.user.default);


    for (let plan in plans.user) {
      if (userSettings?.[plan] > now) {
        activeSubs.push(plan);
        activeSubs.push('user');
        activeFeatures = activeFeatures.concat(plans.user[plan]);
      }
    }

    for (let plan in plans.group) {
      if (groupSettings?.[plan] > now) {
        activeSubs.push(plan);
        activeSubs.push('group');
        activeFeatures = activeFeatures.concat(plans.group[plan]);
      }
    }

    if (userSettings.moder) {
      activeSubs.push('moder');
      activeFeatures = activeFeatures.concat(plans.user.moder);
    } else if (userSettings.guild) {
      activeSubs.push('guild');
      activeFeatures = activeFeatures.concat(plans.user.master);
    }

    if (!gameInfo.monetize) {
      activeSubs.push('beta');
      activeFeatures = activeFeatures.concat(plans.user.master);
    }

    activeFeatures = [...new Set(activeFeatures)];

    premiumSubsRef.current = activeSubs;
    premiumFeaturesRef.current = activeFeatures;

    setPremiumIsLoaded(true);

  }, [gameIsLoaded]);

};