// OpacityControl.js
import React, { useState, useRef, useEffect } from 'react';
import chroma from 'chroma-js';
import CircleSwitch from "./CircleSwitch/CircleSwitch.js";
import ColorIndicator from './ColorIndicator'; 
import { filterPosition } from './Throttler/filterPosition.js'; // путь к вашему хуку

const OpacityControl = ({ 
  canvasContext,
  brushContext,
  drawMethods,

  icon,
  available,
  onContextMenu,
 }) => {

    const {
      color, 
      gradientColor,
      gradientPaletteRef,

      setTheColorFun, 
      activeTool, 
      isChangingOpacity, setIsChangingOpacity,

    } = brushContext;

    const {
      changeTool, 
    } = drawMethods;

    const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 }); 
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 }); 
    const hasMoved = useRef(false);

    const startChangingOpacity = (event) => {

        if (event?.button === 2) { return; }

        setIsChangingOpacity(true);

        const x = event.clientX || event.touches[0]?.clientX;
        const y = event.clientY || event.touches[0]?.clientY;

        setClickPosition({ x, y }); // Обновление позиции кружка
        setPointerPosition({ x, y }); // Обновление позиции кружка

        hasMoved.startTime = Date.now();
        hasMoved.startTouchPoint = event?.touches?.[0] || event;
    };

    useEffect(() => {

        const handlePointerMove = (event) => {

            const workColor = gradientPaletteRef.current ? gradientColor : color;

            if (event?.button === 2) { return; }
            if (!isChangingOpacity) return;

            let position = {
              x: event.clientX || event.touches[0]?.clientX,
              y: event.clientY || event.touches[0]?.clientY,
            }
  
            const filteredPosition = filterPosition(position, 'pointerPosition', 100)

            const diffY = pointerPosition.y - filteredPosition.y;
            setPointerPosition(filteredPosition); // Обновление позиции кружка
            
            if (diffY === 0) return;

            // Вычисление и изменение альфа-канала
            let newColor = chroma(workColor).alpha(Math.max(0, Math.min(1, chroma(workColor).alpha() - diffY * 0.005))).hex('rgba');
            setTheColorFun(newColor);

            hasMoved.current = true; // Установка при перемещении

        };

        const handlePointerUp = (event) => {

            if (event?.button === 2) { return; }

            setIsChangingOpacity(false);
            if (hasMoved.startTouchPoint && Date.now() - hasMoved.startTime < 350) {
              changeTool("effect")
            }
        };

        if (isChangingOpacity) {
            document.addEventListener('mousemove', handlePointerMove);
            document.addEventListener('mouseup', handlePointerUp);
            document.addEventListener('touchmove', handlePointerMove);
            document.addEventListener('touchend', handlePointerUp);
        }

        return () => {
            document.removeEventListener('mousemove', handlePointerMove);
            document.removeEventListener('mouseup', handlePointerUp);
            document.removeEventListener('touchmove', handlePointerMove);
            document.removeEventListener('touchend', handlePointerUp);
        };
    }, [isChangingOpacity, color, gradientColor, setTheColorFun, changeTool]);

    return (
      <div>
        <div
            onContextMenu={onContextMenu}
            onMouseDown={startChangingOpacity}
            onTouchStart={startChangingOpacity}
            style={{
                cursor: 'pointer', 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50px',
                height: '50px',
            }}
        >
            <CircleSwitch
									icon={icon}
                  available={available}
									isActive={activeTool === "effect"}
                  menu={true}
								/>
        </div>
        {isChangingOpacity && (
               <ColorIndicator
               canvasContext={canvasContext}
               brushContext={brushContext}
               
               position={pointerPosition}
               clickPosition={clickPosition}
             />
            )}
      </div>
    );
};

export default OpacityControl;